import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { AlertController, MenuController, Platform, ToastController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { FirebaseDataCollectionService } from './services/firebase-data-collection.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import * as firebase from 'firebase';
// import { HTTP } from '@ionic-native/http/ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  loggedIn = false;
  dark = false;
  links;

  userIsAdmin = false;
  userIsSuperAdmin = false;
  userIsPriviledge = false;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private firebaseDataCollectionService : FirebaseDataCollectionService,
    private iab: InAppBrowser,
    public toastController : ToastController,
    public activateRoute : ActivatedRoute
    // private nativeHTTP : HTTP
  ) {
    this.initializeApp();
  }

  async ngOnInit() {

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.styleBlackTranslucent();
      this.statusBar.backgroundColorByHexString('#000000');
      this.statusBar.overlaysWebView(false); 
      this.splashScreen.hide();


    });


    
  };


}
