import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from 'angularfire2/firestore';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { LoadingController } from '@ionic/angular';
 
export interface Data {
  mainAgenda?: any,
  schedule: any,
  speakers: any
}
 
@Injectable({
  providedIn: 'root'
})
export class FirebaseDataCollectionService {
  private data: Observable<Data[]>;
  private dataCollection: AngularFirestoreCollection<Data>;

  mainAgendasRefRef: AngularFireList<any>;
  tasks: Observable<any[]>;
 
  constructor(private afs: AngularFirestore, public db: AngularFireDatabase, public loadingController : LoadingController) {
    
    // this.dataCollection = this.afs.collection<Data>('vasco-conference-app-default-rtdb');
    // this.data = this.dataCollection.snapshotChanges().pipe(
    //   map(actions => {
    //     return actions.map(a => {
    //       const data = a.payload.doc.data();
    //       const id = a.payload.doc.id;
    //       return { id, ...data };
    //     });
    //   })
    // );
  };

  getMembershipByData(id, dataKey) {
    return this.db.object(`/le-club-users/${id}/${dataKey}`).valueChanges();
  };

  getRelatedCompanyLogo(name){
    return this.db.object(`/related-company/${name}`).valueChanges();
  }


}